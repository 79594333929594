import ApiConfig from './ApiConfig'
import { DashboardClient_V1 } from './generated/api.generated.v1'
import variables from '@/variables'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // Data Source
    fetchSuicideStatistic() {
      const api = new DashboardClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getSuicideStatistic()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchIntakeStatistic() {
      const api = new DashboardClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getIntakeStatistic()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchPopulationStatistic() {
      const api = new DashboardClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getPopulationStatistic()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // Placeholder for Sick Call Requests

    // Site Alerts
    fetchAlertStatistic() {
      const api = new DashboardClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getAlertStatistic()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // Site Tasks
    fetchTaskStatistic() {
      const api = new DashboardClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getTaskStatistic()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // Site MAR Distributions
    fetchMARStatistic() {
      const api = new DashboardClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getMARStatistic()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // Site Patient Appointments
    fetchAppointmentSchedule() {
      const api = new DashboardClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getAppointmentSchedule()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    postPatientAppointment(context, payload) {
      const api = new DashboardClient_V1(new ApiConfig(), variables.INTERNAL_API)

      console.log('API', payload)

      return new Promise((resolve, reject) => {
        api
          .postPatientAppointment(payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // Site Sickest Patient
    fetchSickestPatients() {
      const api = new DashboardClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getSickestPatients()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    postSickestPatient(context, payload) {
      const api = new DashboardClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .postSickestPatient(payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // Site MAR Refusals
    fetchMARRefusalStatistic() {
      const api = new DashboardClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getMARRefusalStatistic()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchConcurrentRefusals() {
      const api = new DashboardClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getConcurrentRefusals()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
