<template>
  <div>
    <v-row>
      <v-col
        lg="3"
        md="4"
        sm="6"
        class="align-self-end"
      >
        <home-card-suicide-watches></home-card-suicide-watches>
      </v-col>

      <v-col
        lg="3"
        md="4"
        sm="6"
        class="align-self-end"
      >
        <home-card-average-population></home-card-average-population>
      </v-col>

      <v-col
        lg="3"
        md="4"
        sm="6"
        class="align-self-end"
      >
        <home-card-daily-bookings></home-card-daily-bookings>
      </v-col>

      <v-col
        lg="3"
        md="4"
        sm="6"
        class="align-self-end"
      >
        <statistics-card-with-images
          :avatar="sickCalls.avatar"
          :avatar-width="sickCalls.avatarWidth"
          :change="sickCalls.change"
          :chip-color="sickCalls.chipColor"
          :chip-text="sickCalls.chipText"
          :statistics="sickCalls.statistics"
          :stat-title="sickCalls.statTitle"
        ></statistics-card-with-images>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="6"
        sm="12"
        order="5"
        order-md="3"
      >
        <home-card-alerts></home-card-alerts>
      </v-col>

      <v-col
        cols="12"
        md="6"
        sm="12"
        order="5"
        order-md="3"
      >
        <home-card-tasks></home-card-tasks>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="4"
        sm="6"
      >
        <v-row>
          <v-col
            cols="12"
          >
            <home-card-m-a-r-distributions></home-card-m-a-r-distributions>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        cols="12"
        md="4"
        sm="6"
      >
        <home-card-appointment-schedule></home-card-appointment-schedule>
      </v-col>

      <v-col
        cols="12"
        md="4"
        sm="6"
      >
        <home-card-sickest-patients></home-card-sickest-patients>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <home-card-medication-refusals></home-card-medication-refusals>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import StatisticsCardWithImages from '@core/components/statistics-card/StatisticsCardWithImages.vue'

// Cards
import HomeCardMedicationRefusals from './HomeCardMedicationRefusals.vue'
import HomeCardAlerts from './HomeCardAlerts.vue'
import HomeCardAppointmentSchedule from './HomeCardAppointmentSchedule.vue'
import HomeCardSickestPatients from './HomeCardSickestPatients.vue'
import HomeCardTasks from './HomeCardTasks.vue'
import HomeCardSuicideWatches from './HomeCardSuicideWatches.vue'
import HomeCardDailyBookings from './HomeCardDailyBookings.vue'
import HomeCardAveragePopulation from './HomeCardAveragePopulation.vue'
import HomeCardMARDistributions from './HomeCardMARDistributions.vue'

export default {
  components: {
    StatisticsCardWithImages,

    HomeCardAppointmentSchedule,
    HomeCardSickestPatients,
    HomeCardAlerts,
    HomeCardTasks,
    HomeCardMedicationRefusals,
    HomeCardSuicideWatches,
    HomeCardDailyBookings,
    HomeCardAveragePopulation,
    HomeCardMARDistributions,
  },
  setup() {
    // Header Statistics Cards


    const sickCalls = {
      statTitle: 'Daily Sick Calls',
      statistics: '40 Requests',
      change: '-5%',
      chipText: 'Daily',
      chipColor: 'neutral',
      avatar: require('@/assets/images/avatars/genericFemale.png'),
      avatarWidth: '100',
    }

    return {
      sickCalls,
    }
  },
}
</script>
