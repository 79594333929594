import store from '@/store'
import { ref, watch } from '@vue/composition-api'

const suicideWatch = ref({})
const dailyIntake = ref({})
const averagePopulation = ref({})
const siteAlerts = ref([{}])
const siteTasks = ref([{}])
const siteDistributions = ref([{}])
const marRefusals = ref([{}])
const concurrentRefusals = ref([{}])
const appointmentSchedule = ref([{}])
const sickestPatients = ref([{}])

const displayDate = date => new Date(date).toISOString().split('T')[0]
const postDate = date => new Date(date).toISOString()

export default function useDashboardList() {
  // fetch data
  const fetchSuicideStatistic = () => {
    console.log('Reached Service Store')
    store
      .dispatch('app-dashboard/fetchSuicideStatistic')
      .then(response => {
        suicideWatch.value = response

        console.log(suicideWatch.value)

        // remove loading state
        //loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([], () => {
    //loading.value = true
    fetchSuicideStatistic()
  })

  const fetchIntakeStatistic = () => {
    store
      .dispatch('app-dashboard/fetchIntakeStatistic')
      .then(response => {
        dailyIntake.value = response

        console.log(dailyIntake.value)

        // remove loading state
        //loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([], () => {
    //loading.value = true
    fetchIntakeStatistic()
  })

  const fetchPopulationStatistic = () => {
    store
      .dispatch('app-dashboard/fetchPopulationStatistic')
      .then(response => {
        averagePopulation.value = response

        console.log(averagePopulation.value)

        // remove loading state
        //loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([], () => {
    //loading.value = true
    fetchPopulationStatistic()
  })

  const fetchAlertStatistic = () => {
    store
      .dispatch('app-dashboard/fetchAlertStatistic')
      .then(response => {
        siteAlerts.value = response

        console.log(siteAlerts.value)

        // remove loading state
        //loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([], () => {
    //loading.value = true
    fetchAlertStatistic()
  })

  const fetchTaskStatistic = () => {
    store
      .dispatch('app-dashboard/fetchTaskStatistic')
      .then(response => {
        siteTasks.value = response

        console.log(siteTasks.value)

        // remove loading state
        //loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([], () => {
    //loading.value = true
    fetchTaskStatistic()
  })

  // MAR Statistics
  const fetchMARStatistic = () => {
    store
      .dispatch('app-dashboard/fetchMARStatistic')
      .then(response => {
        siteDistributions.value = response

        console.log(siteDistributions.value)

        // remove loading state
        //loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([], () => {
    //loading.value = true
    fetchMARStatistic()
  })

   // Appointment Schedule
   const fetchAppointmentSchedule = () => {
    store
      .dispatch('app-dashboard/fetchAppointmentSchedule')
      .then(response => {
        appointmentSchedule.value = response

        appointmentSchedule.value.forEach(appointment => {
          console.log('JSON Date', new Date(appointment.time).toLocaleTimeString())
          appointment.time = new Date(appointment.time).toLocaleString()
        })


        console.log(appointmentSchedule.value)

        // remove loading state
        //loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([], () => {
    //loading.value = true
    fetchAppointmentSchedule()
  })

  const postPatientAppointment = patientAppointment => {
    //patientAppointment.time = postDate(patientAppointment.time)
    console.log('Posting Appointment', patientAppointment)
    store
      .dispatch('app-dashboard/postPatientAppointment', patientAppointment)
      .then(response => {
        console.log('Response', response)

        // remove loading state
        //loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([], () => {
    //loading.value = true
    postPatientAppointment()
  })

  // Site Sickest
  const fetchSickestPatients = () => {
    store
      .dispatch('app-dashboard/fetchSickestPatients')
      .then(response => {
        sickestPatients.value = response

        console.log(sickestPatients.value)

        // remove loading state
        //loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([], () => {
    //loading.value = true
    fetchSickestPatients()
  })

  const postSickestPatient = siteSickestPatient => {
    console.log(siteSickestPatient)
    store
      .dispatch('app-dashboard/postSickestPatient', siteSickestPatient)
      .then(response => {
        console.log(response)

        // remove loading state
        //loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([], () => {
    //loading.value = true
    postSickestPatient()
  })

  // MAR Refusals

  const fetchMARRefusalStatistic = () => {
    store
      .dispatch('app-dashboard/fetchMARRefusalStatistic')
      .then(response => {
        console.log('Refusal Stat Response', response)
        marRefusals.value = response

        // remove loading state
        //loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([], () => {
    //loading.value = true
    fetchMARRefusalStatistic()
  })

  const fetchConcurrentRefusals = () => {
    store
      .dispatch('app-dashboard/fetchConcurrentRefusals')
      .then(response => {
        console.log('Concurrent Refusal Response', response)
        concurrentRefusals.value = response

        // remove loading state
        //loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([], () => {
    //loading.value = true
    fetchConcurrentRefusals()
  })

  return {
    suicideWatch,
    fetchSuicideStatistic,

    dailyIntake,
    fetchIntakeStatistic,

    fetchPopulationStatistic,
    averagePopulation,

    fetchAlertStatistic,
    siteAlerts,

    siteTasks,
    fetchTaskStatistic,

    fetchMARStatistic,
    siteDistributions,

    fetchAppointmentSchedule,
    appointmentSchedule,

    postPatientAppointment,

    fetchSickestPatients,
    sickestPatients,

    postSickestPatient,

    fetchMARRefusalStatistic,
    marRefusals,

    fetchConcurrentRefusals,
    concurrentRefusals,
  }
}
