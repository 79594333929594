<template>
  <div>
    <v-dialog
      v-model="isSickestFormActive"
      persistent
      max-width="25%"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Site Sickest Details</span>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="SiteSickestPatientForm"
          >
            <v-row>
              <v-col>
                <patient-select
                  :patient="siteSickestPatientDTO.patient"
                  :readonly="!isEdit"
                  @patient-select="updatePatientId"
                ></patient-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-switch
                  v-model="siteSickestPatientDTO.isActive"
                  color="primary"
                  :value="siteSickestPatientDTO.isActive != null ? siteSickestPatientDTO.isActive : true"
                  :label="siteSickestPatientDTO.isActive ? 'Active' : 'Inactive'"
                  :readonly="!isEdit"
                >
                </v-switch>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="text-right">
                <v-btn
                  color="error darken-1"
                  type="reset"
                  text
                  @click="closeForm()"
                >
                  Close
                </v-btn>
                <v-btn
                  v-if="isEdit"
                  color="primary darken-1"
                  text
                  @click="submitForm(siteSickestPatientDTO)"
                >
                  Save
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import store from '@/store'
import { required } from '@core/utils/validation'
import { ref, onUnmounted } from '@vue/composition-api'

import dashboardService from '@/services/DashboardService'
import useDashboardService from '@/store/useDashboardService'

import PatientSelect from '@/components/patient/PatientSelect.vue'

import { mdiClose, mdiCalendar } from '@mdi/js'

export default {
  components: {
    PatientSelect,
  },
  model: {
    prop: 'isSickestFormActive',
    event: 'update:is-sickest-form-active',
  },
  props: {
    isSickestFormActive: {
      type: Boolean,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: true,
    },
    siteSickestPatientDTO: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    console.log('Site Sickest', props.siteSickestPatientDTO)
    props.siteSickestPatientDTO.isActive = props.siteSickestPatientDTO.isActive == undefined ? true : props.siteSickestPatientDTO.isActive

    const DASHBOARD_APP = 'app-dashboard'

    // Register module
    if (!store.hasModule(DASHBOARD_APP)) {
      store.registerModule(DASHBOARD_APP, dashboardService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DASHBOARD_APP)) store.unregisterModule(DASHBOARD_APP)
    })

    const { postSickestPatient } = useDashboardService()

    const siteSickestForm = ref(null)
    const menuSD = ref(false)
    const menuDD = ref(false)
    const menuref = ref(null)

    const save = d => {
      menuref.value.save(d)
    }

    const refreshParent = () => {
      emit('refetch-data')
    }

    const closeForm = () => {
      emit('update-dialog')
    }

    return {
      siteSickestForm,
      closeForm,
      refreshParent,
      required,
      menuSD,
      menuDD,
      menuref,
      save,

      postSickestPatient,

      icons: {
        mdiClose,
        mdiCalendar,
      },
    }
  },
  methods: {
    validate() {
      if (this.siteSickestForm.value.validate(this.siteSickestPatientDTO)) {
        return true
      }

      return false
    },

    submitForm() {
      console.log('posting', this.siteSickestPatientDTO)

      this.siteSickestPatientDTO.isActive = this.siteSickestPatientDTO.isActive == null ? false : this.siteSickestPatientDTO.isActive
      this.postSickestPatient(this.siteSickestPatientDTO)

      this.closeForm()
      this.refreshParent()
    },
    updatePatientId(patientDTO) {
      console.log(patientDTO)
      this.siteSickestPatientDTO.patientId = patientDTO.id
      console.log(this.siteSickestPatientDTO)
    },
  },
}
</script>
