<template>
  <div>
    <home-list-card
      :data="appointmentSchedule"
      report="Active Patients"
      title="Appointment Schedule"
      subtitle="Daily Patient Appointments"
      @item-select="selectAppointment"
    ></home-list-card>
    <v-card>
      <patient-appointment-add-edit
        v-if="isAppointmentFormActive"
        :is-appointment-form-active="isAppointmentFormActive"
        :patient-appointment-d-t-o="patientAppointmentDTO"
        :is-edit="true"
        @update-dialog="updateDialog"
      ></patient-appointment-add-edit>
    </v-card>
  </div>
</template>

<script>
import { mdiCalendarBlankOutline, mdiDotsVertical } from '@mdi/js'

import { onUnmounted, ref } from '@vue/composition-api'
import store from '@/store'

import dashboardService from '@/services/DashboardService'
import useDashboardService from '@/store/useDashboardService'

import HomeListCard from '@/components/cards/HomeListCard.vue'
import PatientAppointmentAddEdit from '@/components/form/PatientAppointmentAddEdit.vue'

export default {
  components: { HomeListCard, PatientAppointmentAddEdit },
  setup() {
    const DASHBOARD_APP = 'app-dashboard'

    // Register module
    if (!store.hasModule(DASHBOARD_APP)) {
      store.registerModule(DASHBOARD_APP, dashboardService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DASHBOARD_APP)) store.unregisterModule(DASHBOARD_APP)
    })

    const { fetchAppointmentSchedule, appointmentSchedule } = useDashboardService()

    const isAppointmentFormActive = ref(false)
    const patientAppointmentDTO = ref({})

    return {
      appointmentSchedule,
      fetchAppointmentSchedule,

      isAppointmentFormActive,
      patientAppointmentDTO,

      icons: {
        mdiDotsVertical,
        mdiCalendarBlankOutline,
      },
    }
  },
  mounted() {
    console.log('Appointment Schedule Mounted')
    this.fetchAppointmentSchedule()
    this.$watch('appointmentSchedule', () => {
      console.log('Appointment Schedule', this.appointmentSchedule)
    })
  },
  methods: {
    selectAppointment(patientAppointmentDTO) {
      console.log('appointment Card', patientAppointmentDTO)
      this.patientAppointmentDTO = patientAppointmentDTO
      if (this.patientAppointmentDTO != null) {
        this.updateDialog('Edit')
      } else { this.updateDialog(null) }
    },
    updateDialog(status) {
      console.log('Status', status)
      console.log('Dialog', this.isAppointmentFormActive)
      if (status == null) {
        this.patientAppointmentDTO = {}
      }
      this.isAppointmentFormActive = !this.isAppointmentFormActive
    },
    refreshPage() {
      this.patientAppointmentDTO = ref({})
      this.fetchAppointmentSchedule()
      this.$watch('appointmentSchedule', () => {
        console.log('Appointment Schedule', this.appointmentSchedule)
      })
    },
  },
}
</script>
