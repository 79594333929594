<template>
  <div>
    <v-card>
      <v-card-title class="align-start">
        <span class="font-weight-semibold">{{ title }}</span>

        <v-spacer></v-spacer>

        <v-btn
          v-if="respond"
          icon
          small
          outlined
          class="mt-n2 me-n"
          @click="selectItem(null)"
        >
          <v-icon>{{ icons.mdiPlus }}</v-icon>
        </v-btn>

        <v-btn
          icon
          small
          class="mt-n2 me-n3"
          @click="isEdit = false, updateDialog()"
        >
          <v-icon class="cursor-pointer">
            {{ icons.mdiDotsVertical }}
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-subtitle class="mb-7">
        <span class="font-weight-semibold text--primary me-1">{{ subtitle }}</span>
      </v-card-subtitle>

      <v-card-text>
        <v-list
          two-line
          class="pt-0 mt-n1 overflow-y-auto"
          v-scroll.self
          max-height="350"
        >
          <div
            v-if="title == 'Appointment Schedule' && data.length != 0"
          >
            <v-list-item
              v-for="(item,index) in data"
              :key="item.Id"
              :class="`d-flex align-center px-0 ${index > 0 ? 'mt-3':''}`"
              @click="selectItem(item)"
            >
              <v-list-item-avatar
                size="50"
                outlined
                v-if="item != null"
              >
                <v-icon
                  v-if="item.patient.patientImage == undefined || item.patient.patientImage == null"
                  color="warning"
                  outlined
                >
                  {{ icons.mdiAccountOutline }}
                </v-icon>
                <v-img
                  v-else
                  :src="item.patientImage"
                ></v-img>
              </v-list-item-avatar>

              <div class="d-flex align-center flex-wrap text-no-wrap flex-grow-1">
                <div>
                  <v-list-item-title class="text-sm font-weight-medium">
                    {{ item.patient.jacketId }}
                    |
                    {{ item.patient.displayName }}
                  </v-list-item-title>
                  <div>
                    <v-list-item-subtitle class="text-xs">
                      <v-icon
                        size="14"
                        class="me-1"
                      >
                        {{ icons.mdiCalendarBlankOutline }}
                      </v-icon>
                      {{ item.time }}
                    </v-list-item-subtitle>

                    <v-spacer></v-spacer>
                    <v-list-item-subtitle class="d-flex align-center flex-wrap text-no-wrap flex-grow-1">
                      <v-chip
                        small
                        :color="resolveColorVariation(item.reason).color"
                        :class="`v-chip-light-bg ${resolveColorVariation(item.reason).color}--text font-weight-semibold mt-1`"
                      >
                        {{ item.reason }}
                      </v-chip>
                      <v-chip
                        small
                        :class="`v-chip-light-bg secondary--text font-weight-semibold mt-1`"
                      >
                        {{ item.location }}
                      </v-chip>
                      <v-chip
                        small
                        :color="resolveColorVariation(item.status).color"
                        :class="`v-chip-light-bg ${resolveColorVariation(item.status).color}--text font-weight-semibold mt-1`"
                      >
                        {{ item.status }}
                      </v-chip>
                    </v-list-item-subtitle>
                  </div>
                </div>
              </div>
            </v-list-item>
          </div>
          <div
            v-if="title == 'Sickest Patients' && data.length != 0"
          >
            <v-list-item
              v-for="(item,index) in data"
              :key="item.patientId"
              :class="`d-flex align-center px-0 ${index > 0 ? 'mt-3':''}`"
              @click="selectItem(item)"
            >
              <v-list-item-avatar
                size="50"
                outlined
              >
                <v-icon
                  v-if="item.patient.patientImage == undefined || item.patient.patientImage == null"
                  color="warning"
                  outlined
                >
                  {{ icons.mdiAccountOutline }}
                </v-icon>
                <v-img
                  v-else
                  :src="item.patientImage"
                ></v-img>
              </v-list-item-avatar>

              <div class="d-flex align-center flex-wrap text-no-wrap flex-grow-1">
                <div>
                  <v-list-item-title class="text-sm font-weight-medium">
                    {{ item.patient.jacketId }}
                    |
                    {{ item.patient.displayName }}
                  </v-list-item-title>
                  <div>
                    <v-list-item-subtitle
                      class="d-flex flex-wrap"
                    >
                      Alerts:
                      <v-chip
                        v-for="alert in item.patient.patientAlerts"
                        :key="alert"
                        outlined
                        color="error"
                        small
                      >
                        {{ alert.alert.alertName }}
                      </v-chip>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle
                      class="d-flex flex-wrap"
                    >
                      Allergies:
                      <v-chip
                        v-for="allergy in item.patient.patientAllergies"
                        :key="allergy"
                        outlined
                        color="secondary"
                        small
                      >
                        {{ allergy.allergyDescriptionCode }}
                      </v-chip>
                    </v-list-item-subtitle>
                  </div>
                </div>
              </div>
            </v-list-item>
          </div>
          <div
            v-if="title == '3+ Refusals' && data.length != 0"
          >
          <v-list-item
              v-for="(item,index) in data"
              :key="item.Id"
              :class="`d-flex align-center px-0 ${index > 0 ? 'mt-3':''}`"
            >
              <v-list-item-avatar
                size="50"
                outlined
              >
                <v-icon
                  color="warning"
                  outlined
                >
                  {{ icons.mdiAccountOutline }}
                </v-icon>
              </v-list-item-avatar>

              <div class="d-flex align-center flex-wrap text-no-wrap flex-grow-1">
                <div>
                  <v-list-item-title class="text-sm font-weight-medium">
                   {{ item.patient.jacketId }} | {{ item.patient.displayName }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="d-flex flex-wrap">
                    {{ item.drugName }}
                  </v-list-item-subtitle>
                </div>
              </div>
            </v-list-item>
          </div>
        </v-list>
      </v-card-text>
    </v-card>

    <div
      v-if="loading || renderDialog"
    >
      <v-dialog
        v-model="loading"
        persistent
        max-width="95%"
      >
        <v-skeleton-loader
          v-if="loading"
          type="table-heading, list-item-two-line"
        ></v-skeleton-loader>
      </v-dialog>


      <v-dialog
        v-model="renderDialog"
        persistent
        max-width="95%"
      >
        <div>
            <v-card>
              <report-render
                v-if="renderDialog"
                :data="selectedReport.reportConfig.dataSource"
                :name="selectedReport.reportName"
                :report-style="selectedReport.reportConfig"
                :source="selectedReport.dataSources"
                @update-dialog="updateDialog"
              ></report-render>
          </v-card>
        </div>
      </v-dialog>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline

import { onUnmounted, ref } from '@vue/composition-api'
import {
  mdiAccountOutline,
  mdiDotsVertical,
  mdiLabelOutline,
  mdiTrendingUp,
  mdiCheckAll,
  mdiClockAlertOutline,
  mdiMessageAlertOutline,
  mdiCupOffOutline,
  mdiPlus,
  mdiCalendarBlankOutline,
} from '@mdi/js'
import store from '@/store'

import reportService from '@/services/ReportService'
import useReportService from '@/store/useReportService'

import ReportRender from '@/apps/report/ReportRender.vue'
import moment from 'moment'

export default {
  components: { ReportRender },
  props: {
    data: {
      type: Array,
      default: () => ({}),
    },
    report: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
    respond: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    renderDialog: false,
    isEdit: false,
  }),
  setup(props, { emit }) {
    console.log('Home list Card', props.title, props.data)
    const REPORT_APP = 'app-report'

    // Register module
    if (!store.hasModule(REPORT_APP)) {
      store.registerModule(REPORT_APP, reportService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REPORT_APP)) {
        store.unregisterModule(REPORT_APP)
      }
    })

    const { fetchSiteReport, singleReport, fetchDataSourceData, reportData, loading } = useReportService()

    const selectedReport = ref({})

    const resolveColorVariation = title => {
      if (title === 'Xray') return { color: 'primary' }

      if (title === 'New') return { color: 'primary' }
      if (title === 'Complete') return { color: 'success' }
      if (title === 'Late') return { color: 'error' }

      return { icon: mdiAccountOutline, color: 'warning' }
    }

    const itemSelect = item => {
      emit('item-select', item)
    }

    return {
      selectedReport,

      singleReport,
      fetchSiteReport,
      fetchDataSourceData,
      reportData,
      loading,

      resolveColorVariation,
      itemSelect,

      // icons
      icons: {
        mdiAccountOutline,
        mdiDotsVertical,
        mdiLabelOutline,
        mdiTrendingUp,
        mdiCheckAll,
        mdiClockAlertOutline,
        mdiMessageAlertOutline,
        mdiCupOffOutline,
        mdiPlus,
        mdiCalendarBlankOutline,
      },
    }
  },
  methods: {
    updateDialog() {
      if (!this.renderDialog) {
        this.fetchSiteReport(this.report)
        this.$watch('singleReport', () => {
          this.selectedReport = this.singleReport.reportStyle
          console.log('singleReport', this.singleReport, this.selectedReport)

          this.fetchDataSourceData(this.selectedReport.dataSources, moment(new Date()).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))
          this.$watch('reportData', () => {
            console.log(this.reportData)
            this.selectedReport.reportConfig.dataSource.data = this.reportData
            console.log('Selected Report', this.selectedReport)
            this.renderDialog = !this.renderDialog
          })
        })
      } else if (this.renderDialog) {
        this.renderDialog = !this.renderDialog
      }
    },
    selectItem(item) {
      console.log('Item Selected', item)
      this.itemSelect(item)
    },
  },

}

</script>
