<template>
  <div>
    <v-dialog
      v-model="isAppointmentFormActive"
      persistent
      max-width="75%"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Appointment Details</span>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="PatientAppointmentForm"
          >
            <v-row>
              <v-col
                cols="12"
                lg="6"
              >
                <patient-select
                  :patient="patientAppointmentDTO.patient"
                  :readonly="!isEdit"
                  @patient-select="updatePatientId"
                ></patient-select>
              </v-col>
              <v-col
                cols="12"
                lg="6"
              >
                <v-text-field
                  v-model="patientAppointmentDTO.location"
                  label="Appointment Location"
                  hint="Hospital"
                  outlined
                  dense
                  :readonly="!isEdit"
                  :rules="[required]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                lg="6"
              >
                <v-text-field
                  v-model="patientAppointmentDTO.reason"
                  label="Appointment Reason"
                  hint="Xray"
                  outlined
                  dense
                  :readonly="!isEdit"
                  :rules="[required]"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                lg="6"
              >
                <v-text-field
                  v-model="patientAppointmentDTO.notes"
                  label="Appointment Notes"
                  hint="Chest Pain"
                  outlined
                  dense
                  :readonly="!isEdit"
                  :rules="[required]"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                lg="6"
              >
                <v-menu
                  ref="menuref"
                  v-model="menuSD"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      :prepend-icon="icons.mdiCalendar"
                      label="Date"
                      hint="YYYY-MM-DD"
                      :readonly="!isEdit"
                      :rules="[required]"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-if="isEdit"
                    ref="picker"
                    v-model="date"
                    color="primary"
                    @change="save"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col
                cols="12"
                lg="6"
              >
                <v-menu
                  ref="menuref"
                  v-model="menuDD"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="time"
                      :prepend-icon="icons.mdiCalendar"
                      label="Time"
                      hint="16:00"
                      :readonly="!isEdit"
                      :rules="[required]"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="isEdit"
                    v-model="time"
                    color="primary"
                    format="24hr"
                    @change="save"
                  ></v-time-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                lg="6"
              >
                <v-radio-group
                  v-model="patientAppointmentDTO.status"
                  row
                  :readonly="!isEdit"
                >
                  <v-radio
                    v-for="(status, index) in appointmentStatus"
                    :key="index"
                    :label="status"
                    :value="status"
                  ></v-radio>
                </v-radio-group>
              </v-col>

              <v-col
                cols="12"
                lg="2"
              >
                <v-switch
                  v-model="patientAppointmentDTO.isActive"
                  color="primary"
                  :value="patientAppointmentDTO.isActive != null ? patientAppointmentDTO.isActive : true"
                  :label="patientAppointmentDTO.isActive ? 'Active' : 'Inactive'"
                  :readonly="!isEdit"
                >
                </v-switch>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="text-right">
                <v-btn
                  color="error darken-1"
                  type="reset"
                  text
                  @click="closeForm()"
                >
                  Close
                </v-btn>
                <v-btn
                  v-if="isEdit"
                  color="primary darken-1"
                  text
                  @click="submitForm(patientAppointmentDTO)"
                >
                  Save
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import store from '@/store'
import { required } from '@core/utils/validation'
import { ref, onUnmounted } from '@vue/composition-api'

import dashboardService from '@/services/DashboardService'
import useDashboardService from '@/store/useDashboardService'

import PatientSelect from '@/components/patient/PatientSelect.vue'

import { mdiClose, mdiCalendar } from '@mdi/js'

export default {
  components: {
    PatientSelect,
  },
  model: {
    prop: 'isAppointmentFormActive',
    event: 'update:is-appointment-form-active',
  },
  props: {
    isAppointmentFormActive: {
      type: Boolean,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: true,
    },
    patientAppointmentDTO: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    console.log('PatientAppointment', props.patientAppointmentDTO)
    props.patientAppointmentDTO.status = props.patientAppointmentDTO.status == undefined ? 'New' : props.patientAppointmentDTO.status
    props.patientAppointmentDTO.isActive = props.patientAppointmentDTO.isActive == undefined ? true : props.patientAppointmentDTO.isActive

    const DASHBOARD_APP = 'app-dashboard'

    // Register module
    if (!store.hasModule(DASHBOARD_APP)) {
      store.registerModule(DASHBOARD_APP, dashboardService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DASHBOARD_APP)) store.unregisterModule(DASHBOARD_APP)
    })

    const { postPatientAppointment } = useDashboardService()

    const patientAppointmentForm = ref(null)
    const menuSD = ref(false)
    const menuDD = ref(false)
    const menuref = ref(null)

    const save = d => {
      menuref.value.save(d)
    }

    const refreshParent = () => {
      emit('refetch-data')
    }

    const closeForm = () => {
      emit('update-dialog')
    }

    const appointmentStatus = ref(['New', 'Active', 'Complete', 'Waiting', 'Late'])

    const tzoffset = (new Date()).getTimezoneOffset()
    const date = props.patientAppointmentDTO.time != null && props.patientAppointmentDTO.time !== undefined ? new Date(props.patientAppointmentDTO.time).toISOString().split('T')[0] : null
    const time = props.patientAppointmentDTO.time != null && props.patientAppointmentDTO.time !== undefined ? new Date(props.patientAppointmentDTO.time).toTimeString().trim().split(' ')[0] : null

    console.log('Time', time)
    console.log('Date', date)

    return {
      patientAppointmentForm,
      closeForm,
      refreshParent,
      required,
      menuSD,
      menuDD,
      menuref,
      save,

      postPatientAppointment,

      appointmentStatus,

      date,
      time,
      tzoffset,

      icons: {
        mdiClose,
        mdiCalendar,
      },
    }
  },
  methods: {
    validate() {
      if (this.patientAppointmentForm.value.validate(this.patientAppointmentDTO)) {
        return true
      }

      return false
    },

    submitForm() {
      this.patientAppointmentDTO.isActive = this.patientAppointmentDTO.isActive == null ? false : this.patientAppointmentDTO.isActive
      const dateTime = this.date + ' ' + this.time

      const time = new Date(Date.parse(dateTime))

      console.log('DateTime', dateTime)
      console.log('Time', time)

      //this.patientAppointmentDTO.time = time


      this.patientAppointmentDTO.time = new Date(dateTime)
      console.log('posting', this.patientAppointmentDTO)
      this.postPatientAppointment(this.patientAppointmentDTO)

      this.closeForm()
      this.refreshParent()
    },
    updatePatientId(patientDTO) {
      console.log(patientDTO)
      this.patientAppointmentDTO.patientId = patientDTO.id
      console.log(this.patientAppointmentDTO)
    },
  },
}
</script>
