<template>
  <div>
    <home-multi-stat-card
      :data="siteDistributions"
      report="MAR"
      title="MAR Distribution Overview"
      subtitle="Medication/Treatment Distribution Overview"
    ></home-multi-stat-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline

import { onUnmounted, ref } from '@vue/composition-api'
import { mdiDotsVertical } from '@mdi/js'
import store from '@/store'

import dashboardService from '@/services/DashboardService'
import useDashboardService from '@/store/useDashboardService'

import HomeMultiStatCard from '@/components/cards/HomeMultiStatCard.vue'

export default {
  components: { HomeMultiStatCard },
  setup() {
    const DASHBOARD_APP = 'app-dashboard'

    // Register module
    if (!store.hasModule(DASHBOARD_APP)) {
      store.registerModule(DASHBOARD_APP, dashboardService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DASHBOARD_APP)) store.unregisterModule(DASHBOARD_APP)
    })

    const { fetchMARStatistic, siteDistributions } = useDashboardService()


    return {
      siteDistributions,
      fetchMARStatistic,

      // icons
      icons: {
        mdiDotsVertical,
      },
    }
  },
  mounted() {
    console.log('MAR Card Mounted')
    this.fetchMARStatistic()
    this.$watch('siteDistributions', () => {
      console.log('Site Distributions', this.siteDistributions)
    })
  },
}

</script>
