<template>
  <div>
    <v-card>
      <v-card-title class="align-start">
        <span class="font-weight-semibold">{{ title }}</span>

        <v-spacer></v-spacer>

        <v-btn
          icon
          small
          class="mt-n2 me-n3"
          @click="isEdit = false, updateDialog()"
        >
          <v-icon class="cursor-pointer">
            {{ icons.mdiDotsVertical }}
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-subtitle class="mb-7">
        <span class="font-weight-semibold text--primary me-1">{{ subtitle }}</span>
      </v-card-subtitle>

      <v-card-text>
        <v-row>
          <v-col
            v-for="item in data"
            :key="item.title"
            class="d-flex align-center"
          >
            <v-avatar
              size="44"
              :color="resolveStatisticsIconVariation (item.title).color"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ resolveStatisticsIconVariation (item.title).icon }}
              </v-icon>
            </v-avatar>

            <div class="ms-3">
              <p class="text-xs mb-0">
                {{ item.title }}
              </p>
              <h3 class="text-xl font-weight-bold">
                {{ item.statistic }}
              </h3>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <div
      v-if="loading || renderDialog"
    >
      <v-dialog
        v-model="loading"
        persistent
        max-width="95%"
      >
        <v-skeleton-loader
          v-if="loading"
          type="table-heading, list-item-two-line"
        ></v-skeleton-loader>
      </v-dialog>


      <v-dialog
        v-model="renderDialog"
        persistent
        max-width="95%"
      >
        <div>
            <v-card>
              <report-render
                v-if="renderDialog"
                :data="selectedReport.reportConfig.dataSource"
                :name="selectedReport.reportName"
                :report-style="selectedReport.reportConfig"
                :source="selectedReport.dataSources"
                @update-dialog="updateDialog"
              ></report-render>
          </v-card>
        </div>
      </v-dialog>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline

import { onUnmounted, ref } from '@vue/composition-api'
import {
  mdiAccountOutline,
  mdiDotsVertical,
  mdiLabelOutline,
  mdiTrendingUp,
  mdiCheckAll,
  mdiClockAlertOutline,
  mdiMessageAlertOutline,
  mdiCupOffOutline,
  mdiPill,
} from '@mdi/js'
import store from '@/store'

import reportService from '@/services/ReportService'
import useReportService from '@/store/useReportService'

import ReportRender from '@/apps/report/ReportRender.vue'
import moment from 'moment'

export default {
  components: { ReportRender },
  props: {
    data: {
      type: Array,
      default: () => ({}),
    },
    report: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    renderDialog: false,
    isEdit: false,
  }),
  setup() {
    const REPORT_APP = 'app-report'

    // Register module
    if (!store.hasModule(REPORT_APP)) {
      store.registerModule(REPORT_APP, reportService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REPORT_APP)) {
        store.unregisterModule(REPORT_APP)
      }
    })

    const { fetchSiteReport, singleReport, fetchDataSourceData, reportData, loading } = useReportService()

    const selectedReport = ref({})

    const resolveStatisticsIconVariation = title => {
      if (title === 'Suicide') return { icon: mdiAccountOutline, color: 'error' }
      if (title === 'Self Harm') return { icon: mdiAccountOutline, color: 'error' }
      if (title === 'Alcohol Abuse') return { icon: mdiCupOffOutline, color: 'warning' }
      if (title === 'Benzo Abuse') return { icon: mdiCupOffOutline, color: 'warning' }
      if (title === 'Opioid Abuse') return { icon: mdiPill, color: 'warning' }
      if (title === 'ICE') return { icon: mdiAccountOutline, color: 'warning' }
      if (title === 'FED') return { icon: mdiAccountOutline, color: 'secondary' }
      if (title === 'DOC') return { icon: mdiAccountOutline, color: 'primary' }

      if (title === 'Medical') return { icon: mdiTrendingUp, color: 'primary' }
      if (title === 'Medical History') return { icon: mdiAccountOutline, color: 'success' }
      if (title === 'Mental Health') return { icon: mdiLabelOutline, color: 'warning' }

      if (title === 'Complete') return { icon: mdiCheckAll, color: 'success' }
      if (title === 'Incomplete') return { icon: mdiMessageAlertOutline, color: 'primary' }
      if (title === 'PastDue') return { icon: mdiClockAlertOutline, color: 'error' }

      return { icon: mdiAccountOutline, color: 'warning' }
    }

    return {
      selectedReport,

      singleReport,
      fetchSiteReport,
      fetchDataSourceData,
      reportData,
      loading,

      resolveStatisticsIconVariation,

      // icons
      icons: {
        mdiAccountOutline,
        mdiDotsVertical,
        mdiLabelOutline,
        mdiTrendingUp,
        mdiCheckAll,
        mdiClockAlertOutline,
        mdiMessageAlertOutline,
        mdiCupOffOutline,
        mdiPill,
      },
    }
  },
  methods: {
    updateDialog() {
      if (!this.renderDialog) {
        this.fetchSiteReport(this.report)
        this.$watch('singleReport', () => {
          this.selectedReport = this.singleReport.reportStyle
          console.log(this.singleReport, this.selectedReport)

          this.fetchDataSourceData(this.selectedReport.dataSources, moment(new Date()).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))
          this.$watch('reportData', () => {
            console.log(this.reportData)
            this.selectedReport.reportConfig.dataSource.data = this.reportData
            console.log('Selected Report', this.selectedReport.reportConfig.dataSource.data)
            this.renderDialog = !this.renderDialog
          })
        })
      } else if (this.renderDialog) {
        this.renderDialog = !this.renderDialog
      }
    },
  },

}

</script>
