<template>
  <div>
    <home-stat-card
      :data="data"
      report="Active Suicide Watch"
    ></home-stat-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline

import { onUnmounted, ref } from '@vue/composition-api'
import { mdiDotsVertical } from '@mdi/js'
import store from '@/store'

import dashboardService from '@/services/DashboardService'
import useDashboardService from '@/store/useDashboardService'

import HomeStatCard from '@/components/cards/HomeStatCard.vue'

export default {
  components:{ HomeStatCard },
  setup() {
    const DASHBOARD_APP = 'app-dashboard'

    // Register module
    if (!store.hasModule(DASHBOARD_APP)) {
      store.registerModule(DASHBOARD_APP, dashboardService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DASHBOARD_APP)) store.unregisterModule(DASHBOARD_APP)
    })

    const { fetchSuicideStatistic, suicideWatch } = useDashboardService()

    const selectedReport = ref({})

    const data = ref({
      title: 'Suicide Watch',
      change: 0,
      statistic: '',
      chipText: 'Today',
      chipColor: 'warning',
      avatar: require('@/assets/images/alerts/suicideWatch.png'),
      avatarWidth: '135',
    })

    return {
      data,
      selectedReport,

      fetchSuicideStatistic,
      suicideWatch,

      // icons
      icons: {
        mdiDotsVertical,
      },
    }
  },
  mounted() {
    console.log('Suicide Card Mounted')
    this.fetchSuicideStatistic()
    this.$watch('suicideWatch', () => {
      this.data.statistic = this.suicideWatch.statistic
      this.data.title = this.suicideWatch.title
      console.log('Site Alerts', this.suicideWatch, this.data)
    })
  },
}

</script>
