<template>
  <div>
    <v-card
      class="mx-auto"
    >
      <v-card-title class="align-start text-base font-weight-semibold">
        <span class="font-weight-semibold">{{ data.title }}</span>
        <v-spacer></v-spacer>

        <v-btn
          icon
          small
          class="mt-n2 me-n3"
          @click="isEdit = false, updateDialog()"
        >
          <v-icon class="cursor-pointer">
            {{ icons.mdiDotsVertical }}
          </v-icon>
        </v-btn>
      </v-card-title>

      <div class="d-flex">
        <div>
          <v-card-title class="d-inline-block text-truncate"> {{ data.statistic }} </v-card-title>
          <v-spacer></v-spacer>

          <v-card-text>
            <v-chip
              small
              :color="data.chipColor"
              class="v-chip-light-bg font-weight-semibold"
              :class="`${data.chipColor}--text`"
            >
              {{ data.chipText }}
            </v-chip>
          </v-card-text>
        </div>
        <v-spacer></v-spacer>
        <div max-width="50%">
          <v-avatar
            size="125"
          >
            <v-img :src="data.avatar"></v-img>
          </v-avatar>
        </div>
      </div>
    </v-card>

  <div
      v-if="loading || renderDialog"
    >
      <v-dialog
        v-model="loading"
        persistent
        max-width="95%"
      >
        <v-skeleton-loader
          v-if="loading"
          type="table-heading, list-item-two-line"
        ></v-skeleton-loader>
      </v-dialog>


      <v-dialog
        v-model="renderDialog"
        persistent
        max-width="95%"
      >
        <div>
            <v-card>
              <report-render
                v-if="renderDialog"
                :data="selectedReport.reportConfig.dataSource"
                :source="selectedReport.dataSources"
                :name="selectedReport.reportName"
                :report-style="selectedReport.reportConfig"
                @update-dialog="updateDialog"
              ></report-render>
          </v-card>
        </div>
      </v-dialog>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline

import { onUnmounted, ref } from '@vue/composition-api'
import { mdiDotsVertical } from '@mdi/js'
import store from '@/store'

import reportService from '@/services/ReportService'
import useReportService from '@/store/useReportService'

import ReportRender from '@/apps/report/ReportRender.vue'
import moment from 'moment'

export default {
  components: { ReportRender },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    report: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    renderDialog: false,
    isEdit: false,
    statistic: '',
  }),
  setup() {
    const REPORT_APP = 'app-report'

    // Register module
    if (!store.hasModule(REPORT_APP)) {
      store.registerModule(REPORT_APP, reportService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REPORT_APP)) {
        store.unregisterModule(REPORT_APP)
      }
    })

    const { fetchSiteReport, singleReport, fetchDataSourceData, reportData, loading } = useReportService()

    const selectedReport = ref({})


    return {
      selectedReport,

      singleReport,
      fetchSiteReport,
      fetchDataSourceData,
      reportData,
      loading,

      // icons
      icons: {
        mdiDotsVertical,
      },
    }
  },
  methods: {
    updateDialog() {
      if (!this.renderDialog) {
        this.fetchSiteReport(this.report)
        this.$watch('singleReport', () => {
          this.selectedReport = this.singleReport.reportStyle
          console.log(this.singleReport, this.selectedReport)

          this.fetchDataSourceData(this.selectedReport.dataSources, moment(new Date()).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))
          this.$watch('reportData', () => {
            console.log(this.reportData)
            this.selectedReport.reportConfig.dataSource.data = this.reportData
            console.log('Selected Report', this.selectedReport.reportConfig.dataSource.data)
            this.renderDialog = !this.renderDialog
          })
        })
      } else if (this.renderDialog) {
        this.renderDialog = !this.renderDialog
      }
    },
  },

}

</script>
