<template>
  <div>
    <home-multi-stat-card
      :data="siteAlerts"
      report="Active Alerts"
      title="Alert Overview"
      subtitle="Current Patient Alerts"
    ></home-multi-stat-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'

import dashboardService from '@/services/DashboardService'
import useDashboardService from '@/store/useDashboardService'

import HomeMultiStatCard from '@/components/cards/HomeMultiStatCard.vue'

export default {
  components: { HomeMultiStatCard },
  setup() {
    const DASHBOARD_APP = 'app-dashboard'

    // Register module
    if (!store.hasModule(DASHBOARD_APP)) {
      store.registerModule(DASHBOARD_APP, dashboardService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DASHBOARD_APP)) store.unregisterModule(DASHBOARD_APP)
    })

    const { siteAlerts, fetchAlertStatistic } = useDashboardService()

    return {
      fetchAlertStatistic,
      siteAlerts,
    }
  },
  mounted() {
    console.log('Alert Mounted')
    this.fetchAlertStatistic()
    this.$watch('siteAlerts', () => {
      console.log('Alerts', this.siteAlerts)
    })
  },
}
</script>
