<template>
  <div>
    <home-multi-stat-card
      :data="siteTasks"
      report="Active Tasks"
      title="Task Overview"
      subtitle="Current Patient Tasks"
    ></home-multi-stat-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'

import dashboardService from '@/services/DashboardService'
import useDashboardService from '@/store/useDashboardService'

import HomeMultiStatCard from '@/components/cards/HomeMultiStatCard.vue'

export default {
  components: { HomeMultiStatCard },
  setup() {
    const DASHBOARD_APP = 'app-dashboard'

    // Register module
    if (!store.hasModule(DASHBOARD_APP)) {
      store.registerModule(DASHBOARD_APP, dashboardService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DASHBOARD_APP)) store.unregisterModule(DASHBOARD_APP)
    })

    const { siteTasks, fetchTaskStatistic } = useDashboardService()

    return {
      fetchTaskStatistic,
      siteTasks,
    }
  },
  mounted() {
    console.log('Task Mounted')
    this.fetchTaskStatistic()
    this.$watch('siteTasks', () => {
      console.log('Tasks', this.siteTasks)
    })
  },
}
</script>
