<template>
  <div>
    <home-list-card
      :data="sickestPatients"
      report="Active Patients"
      title="Sickest Patients"
      subtitle="10 Sickest Patients"
      @item-select="selectSickest"
    ></home-list-card>
    <v-card>
      <sickest-patient-add-edit
        v-if="isSickestFormActive"
        :is-sickest-form-active="isSickestFormActive"
        :site-sickest-patient-d-t-o="siteSickestPatientDTO"
        :is-edit="true"
        @update-dialog="updateDialog"
      ></sickest-patient-add-edit>
    </v-card>
  </div>
</template>

<script>
import { mdiCalendarBlankOutline, mdiDotsVertical } from '@mdi/js'

import { onUnmounted, ref } from '@vue/composition-api'
import store from '@/store'

import dashboardService from '@/services/DashboardService'
import useDashboardService from '@/store/useDashboardService'

import HomeListCard from '@/components/cards/HomeListCard.vue'
import SickestPatientAddEdit from '@/components/form/SickestPatientAddEdit.vue'

export default {
  components: { HomeListCard, SickestPatientAddEdit },
  setup() {
    const DASHBOARD_APP = 'app-dashboard'

    // Register module
    if (!store.hasModule(DASHBOARD_APP)) {
      store.registerModule(DASHBOARD_APP, dashboardService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DASHBOARD_APP)) store.unregisterModule(DASHBOARD_APP)
    })

    const { fetchSickestPatients, sickestPatients } = useDashboardService()

    const isSickestFormActive = ref(false)
    const siteSickestPatientDTO = ref({})

    return {
      sickestPatients,
      fetchSickestPatients,

      isSickestFormActive,
      siteSickestPatientDTO,

      icons: {
        mdiDotsVertical,
        mdiCalendarBlankOutline,
      },
    }
  },
  mounted() {
    console.log('Site Sickest Mounted')
    this.fetchSickestPatients()
    this.$watch('sickestPatients', () => {
      console.log('Site Sickest', this.sickestPatients)
    })
  },
  methods: {
    selectSickest(siteSickestPatientDTO) {
      console.log('Sickest Card', siteSickestPatientDTO)
      this.siteSickestPatientDTO = siteSickestPatientDTO
      if (this.siteSickestPatientDTO != null) {
        this.updateDialog('Edit')
      } else { this.updateDialog(null) }
    },
    updateDialog(status) {
      console.log('Status', status)
      console.log('Dialog', this.isSickestFormActive)
      if (status == null) {
        this.siteSickestPatientDTO = {}
      }
      this.isSickestFormActive = !this.isSickestFormActive
    },
    refreshPage() {
      this.siteSickestPatientDTO = ref({})
      this.fetchSickestPatients()
      this.$watch('sickestPatients', () => {
        console.log('Site Sickest', this.sickestPatients)
      })
    },
  },
}
</script>
