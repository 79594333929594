<template>
  <div>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col
            cols="8"
          >
            <v-card-title class="align-start">
              <span class="font-weight-semibold">Total Medication Refusals</span>



              <v-spacer></v-spacer>

              <v-btn
                icon
                small
                class="mt-n2 me-n3"
                @click="isEdit = false, updateDialog()"
              >
                <v-icon class="cursor-pointer">
                  {{ icons.mdiDotsVertical }}
                </v-icon>
              </v-btn>
            </v-card-title>

            <v-card-subtitle class="mb-7">
              <span class="font-weight-semibold text--primary me-1">This Week</span>
            </v-card-subtitle>

            <vue-apex-charts
              v-if="xCat.length != 0"
              id="total-Medication-Refusals-chart"
              v-model="refusals"
              height="320"
              width="100%"
              :options="chartOptions"
              :series="refusals"
            ></vue-apex-charts>
          </v-col>
          <v-col
            cols="4"
          >
            <div>
              <home-list-card
                v-model="concurrentRefusals"
                :data="concurrentRefusals"
                report="Active Patients"
                title="3+ Refusals"
                subtitle="Concurrent Refusals this Month"
                :respond="false"
              ></home-list-card>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="renderDialog"
      persistent
      max-width="95%"
    >
      <div>
        <v-card>
          <report-render
            v-if="renderDialog"
            :data="selectedReport.reportConfig.dataSource"
            :name="selectedReport.reportName"
            :report-style="selectedReport.reportConfig"
            :source="selectedReport.dataSources"
            @update-dialog="updateDialog"
          ></report-render>
        </v-card>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mdiCalendarBlankOutline, mdiDotsVertical } from '@mdi/js'

import VueApexCharts from 'vue-apexcharts'
import { getVuetify } from '@core/utils'
import { kFormatter } from '@core/utils/filter'

import { onUnmounted, ref } from '@vue/composition-api'
import store from '@/store'

import dashboardService from '@/services/DashboardService'
import useDashboardService from '@/store/useDashboardService'

import HomeListCard from '@/components/cards/HomeListCard.vue'

import reportService from '@/services/ReportService'
import useReportService from '@/store/useReportService'

import ReportRender from '@/apps/report/ReportRender.vue'
import moment from 'moment'

export default {
  components: { HomeListCard, VueApexCharts, ReportRender },
  data: () => ({
    renderDialog: false,
    report: 'Active Alerts',
  }),
  setup() {
    const DASHBOARD_APP = 'app-dashboard'

    // Register module
    if (!store.hasModule(DASHBOARD_APP)) {
      store.registerModule(DASHBOARD_APP, dashboardService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DASHBOARD_APP)) store.unregisterModule(DASHBOARD_APP)
    })

    const { fetchMARRefusalStatistic, marRefusals, fetchConcurrentRefusals, concurrentRefusals } = useDashboardService()

    const REPORT_APP = 'app-report'

    // Register module
    if (!store.hasModule(REPORT_APP)) {
      store.registerModule(REPORT_APP, reportService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REPORT_APP)) {
        store.unregisterModule(REPORT_APP)
      }
    })

    const { fetchSiteReport, singleReport, fetchDataSourceData, reportData } = useReportService()

    const selectedReport = ref({})

    const isAppointmentFormActive = ref(false)
    const patientAppointmentDTO = ref({})

    const $vuetify = getVuetify()

    const refusals = [
      {
        name: 'Refused',
        data: [],
      },
    ]

    const xCat = []

    const chartOptions = {
      colors: [
        $vuetify.theme.currentTheme.primary,
        $vuetify.theme.currentTheme.warning,
        $vuetify.theme.currentTheme.secondary,
      ],
      chart: {
        type: 'bar',
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          columnWidth: '35%',
          startingShape: 'rounded',
          endingShape: 'rounded',
        },
      },
      xaxis: {
        categories: xCat,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          formatter: value => kFormatter(value, 0),
        },
      },
      grid: {
        strokeDashArray: 7,
      },
      dataLabels: {
        enabled: true,
      },
      legend: {
        show: false,
      },
      stroke: {
        curve: 'smooth',
        width: 6,
        lineCap: 'round',
        colors: ['#fff'],
      },
    }

    return {
      concurrentRefusals,
      fetchMARRefusalStatistic,
      marRefusals,
      fetchConcurrentRefusals,

      isAppointmentFormActive,
      patientAppointmentDTO,

      chartOptions,
      refusals,
      xCat,

      fetchSiteReport,
      singleReport,
      fetchDataSourceData,
      reportData,
      selectedReport,

      icons: {
        mdiDotsVertical,
        mdiCalendarBlankOutline,
      },
    }
  },
  created() {
    console.log('Refusals Mounted')
    this.fetchMARRefusalStatistic()
    this.$watch('marRefusals', () => {
      this.xCat = []
      this.marRefusals.forEach(item => {
        this.xCat.push(item.title)
        this.refusals[0].data.push(parseInt(item.statistic, 10))
      })
    this.chartOptions.xaxis.categories = this.xCat
    })

    this.fetchConcurrentRefusals()
    this.$watch('concurrentRefusals', () => {
      console.log('Concurrent Refusals', this.concurrentRefusals)
    })
  },
  methods:{
    updateDialog() {
      if (!this.renderDialog) {
        this.fetchSiteReport(this.report)
        this.$watch('singleReport', () => {
          this.selectedReport = this.singleReport.reportStyle
          console.log(this.singleReport, this.selectedReport)

          this.fetchDataSourceData(this.selectedReport.dataSources, moment(new Date()).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))
          this.$watch('reportData', () => {
            console.log(this.reportData)
            this.selectedReport.reportConfig.dataSource.data = this.reportData
            console.log('Selected Report', this.selectedReport)
            this.renderDialog = !this.renderDialog
          })
        })
      } else if (this.renderDialog) {
        this.renderDialog = !this.renderDialog
      }
    },
  }
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';
@import '~vuetify/src/styles/styles.sass';
@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .total-profit-chart-col {
    @include ltr() {
      border-right: thin solid;
    }
    @include rtl() {
      border-left: thin solid;
    }
  }
}
@include theme--child(total-profit-chart-col) using ($material) {
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    border-color: map-get($material, 'dividers');
  }
}
</style>
