import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,{staticClass:"mx-auto"},[_c(VCardTitle,{staticClass:"align-start text-base font-weight-semibold"},[_c('span',{staticClass:"font-weight-semibold"},[_vm._v(_vm._s(_vm.data.title))]),_c(VSpacer),_c(VBtn,{staticClass:"mt-n2 me-n3",attrs:{"icon":"","small":""},on:{"click":function($event){_vm.isEdit = false, _vm.updateDialog()}}},[_c(VIcon,{staticClass:"cursor-pointer"},[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)],1),_c('div',{staticClass:"d-flex"},[_c('div',[_c(VCardTitle,{staticClass:"d-inline-block text-truncate"},[_vm._v(" "+_vm._s(_vm.data.statistic)+" ")]),_c(VSpacer),_c(VCardText,[_c(VChip,{staticClass:"v-chip-light-bg font-weight-semibold",class:((_vm.data.chipColor) + "--text"),attrs:{"small":"","color":_vm.data.chipColor}},[_vm._v(" "+_vm._s(_vm.data.chipText)+" ")])],1)],1),_c(VSpacer),_c('div',{attrs:{"max-width":"50%"}},[_c(VAvatar,{attrs:{"size":"125"}},[_c(VImg,{attrs:{"src":_vm.data.avatar}})],1)],1)],1)],1),(_vm.loading || _vm.renderDialog)?_c('div',[_c(VDialog,{attrs:{"persistent":"","max-width":"95%"},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[(_vm.loading)?_c(VSkeletonLoader,{attrs:{"type":"table-heading, list-item-two-line"}}):_vm._e()],1),_c(VDialog,{attrs:{"persistent":"","max-width":"95%"},model:{value:(_vm.renderDialog),callback:function ($$v) {_vm.renderDialog=$$v},expression:"renderDialog"}},[_c('div',[_c(VCard,[(_vm.renderDialog)?_c('report-render',{attrs:{"data":_vm.selectedReport.reportConfig.dataSource,"source":_vm.selectedReport.dataSources,"name":_vm.selectedReport.reportName,"report-style":_vm.selectedReport.reportConfig},on:{"update-dialog":_vm.updateDialog}}):_vm._e()],1)],1)])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }