import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"persistent":"","max-width":"25%"},model:{value:(_vm.isSickestFormActive),callback:function ($$v) {_vm.isSickestFormActive=$$v},expression:"isSickestFormActive"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"text-h5"},[_vm._v("Site Sickest Details")])]),_c(VCardText,[_c(VForm,{ref:"SiteSickestPatientForm"},[_c(VRow,[_c(VCol,[_c('patient-select',{attrs:{"patient":_vm.siteSickestPatientDTO.patient,"readonly":!_vm.isEdit},on:{"patient-select":_vm.updatePatientId}})],1)],1),_c(VRow,[_c(VCol,[_c(VSwitch,{attrs:{"color":"primary","value":_vm.siteSickestPatientDTO.isActive != null ? _vm.siteSickestPatientDTO.isActive : true,"label":_vm.siteSickestPatientDTO.isActive ? 'Active' : 'Inactive',"readonly":!_vm.isEdit},model:{value:(_vm.siteSickestPatientDTO.isActive),callback:function ($$v) {_vm.$set(_vm.siteSickestPatientDTO, "isActive", $$v)},expression:"siteSickestPatientDTO.isActive"}})],1)],1),_c(VRow,[_c(VCol,{staticClass:"text-right"},[_c(VBtn,{attrs:{"color":"error darken-1","type":"reset","text":""},on:{"click":function($event){return _vm.closeForm()}}},[_vm._v(" Close ")]),(_vm.isEdit)?_c(VBtn,{attrs:{"color":"primary darken-1","text":""},on:{"click":function($event){return _vm.submitForm(_vm.siteSickestPatientDTO)}}},[_vm._v(" Save ")]):_vm._e()],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }